.external-link {
  color: #fff;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  white-space: nowrap;
  margin-right: 12px;
}

.action-external-link {
  color: #3f51b5;
}

.external-link:hover {
  text-decoration: underline;
  cursor: pointer;
}
